import { Col, Row } from "react-bootstrap"
import "./Banner.css"
const Banner = () => {
    return (
        <Row id="Banner">
            <Col id="Banner-Title" className="mt-auto" style={{top:20+"px"}}>    
                <Row id="Banner-Title-1" md={8}>
                {/* <Row id="Banner-Title-1" md={8} style={{paddingLeft:50+"px"}}> */}
                    <span >Des formateurs adaptés</span>
                </Row>
                {/* <Row id="Banner-Title-2" md={8} style={{paddingLeft:100+"px"}}> */}
                <Row id="Banner-Title-2" md={8}>
                    <span >Pour atteindre l’excellence professionnelle</span>
                </Row>
            </Col>
        </Row>
    )
}

export default Banner