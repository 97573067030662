import Teachers from "../../components/teachers/Teachers"
import { Button, Col, Container, Row } from "react-bootstrap"
import "./BeTeacher.css"

import recruit from "../../assets/prof1-slim.jpg"
// import recruit from "../../assets/prof1.jpeg"

const BeTeacher = () => {
    return (
        <>
            <Row className="justify-content-center NousContacter-Title" style={{paddingTop:10+"px",paddingBottom:10+"px",marginBottom:50+"px"}}>
                <Col md="auto">
                    <h2>Vous souhaitez transmettre votre savoir ? Rejoignez ANDN Services !</h2>
                </Col>
            </Row>
            <Container fluid>
                
                <Row className="justify-content-center align-items-center">
                    <Col md={4} style={{textAlign:"center"}}>
                        <h2>Nous rejoindre, c'est simple !</h2>
                        <h4>

                            ANDN Services est toujours à la recherche de nouveaux talents !
                        </h4>
                    </Col>
                    <Col md={4}>
                        <img src={recruit}/>
                    </Col>
                </Row>
                <Teachers/>
                <Row className="justify-content-center">
                    
                    <Col md="auto">
                        <Button href="/nous-contacter" >Contactez Nous !</Button>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <br></br>
            </Container>
        </>
    )
}

export default BeTeacher