import { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import axios from "axios"


const ContactForm = () => {
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [mail, setMail] = useState("")
    const [telephone, setTelephone ]= useState("")
    const [sujet, setSujet] = useState("")
    const [message, setMessage] = useState("")
    const [entreprise, setEntreprise] = useState("")
    const [siret, setSiret] = useState("")
    // const [send, setSend] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const sendMail = async (nom, prenom, sujet, phone, message, mail, entreprise, siret) => {
        await axios.post("https://data.andn-services.fr/mail", {
            "mail" : "contact@andn-services.fr",
            "nom" : nom,
            "prenom" : prenom,
            "subject" : sujet,
            "text" : "<h3>Une nouvelle personne s'intéresse à ANDN Services !</h3><table><tr><th>Nom</th><td>"+nom+"</td></tr><tr><th>Prénom</th><td>"+prenom+"</td></tr><tr><th>Mail</th><td>"+mail+"</td></tr><tr><th>Numéro</th><td>"+phone+"</td></tr><tr><th>Entreprise</th><td>"+entreprise+"</td></tr><tr><th>N° Siret</th><td>"+siret+"</td></tr><tr><th>Sujet</th><td>"+sujet+"</td></tr></table><br><p>"+message+"</p>"
        }).then((result) => {
            setErrorMessage("Votre message a bien été transmis à nos équipes.")
        }).catch((err)=> {
            setErrorMessage("Veuillez remplir les champs manquants.")
        })
    }
    useEffect(() => {

    })
    
    
    const handleSubmit = (event) =>{
        event.preventDefault()
        
        let checked=true
        
        event.target.nom.style = "border:;"
        event.target.prenom.style = "border:;"
        event.target.email.style = "border:;"
        event.target.telephone.style = "border:;"
        event.target.sujet.style = "border:;"
        event.target.message.style = "border:;"
        event.target.entreprise.style = "border:;"
        event.target.siret.style = "border:;"

        
        if(nom === "" || nom === undefined){
            checked=false
            event.target.nom.style = "border:1px solid red;"
        }
        if(prenom === "" || prenom === undefined){
            checked=false
            event.target.prenom.style = "border:1px solid red;"
        }
        if(mail === "" || mail === undefined){
            checked=false
            event.target.email.style = "border:1px solid red;"
        }
        if(telephone === "" || telephone === undefined){
            checked=false
            event.target.telephone.style = "border:1px solid red;"
        }
        if(sujet === "" || sujet === undefined){
            checked=false
            event.target.sujet.style = "border:1px solid red;"
        }
        if(message === "" || message === undefined){
            checked=false
            event.target.message.style = "border:1px solid red;"
        }
        if(entreprise === "" || entreprise === undefined){
            checked=false
            event.target.entreprise.style = "border:1px solid red;"
        }
        if(siret === "" || siret === undefined){
            checked=false
            event.target.siret.style = "border:1px solid red;"
        }
        if(checked){
            sendMail(nom,prenom,sujet, telephone, message, mail, entreprise, siret)
            console.log(nom+" "+prenom+" "+sujet+" "+telephone+" "+message+" "+mail+" "+entreprise+" "+siret)
            
            setErrorMessage("Votre message a bien été transmis à nos équipes.")
            
        }else{
            console.log(nom+" "+prenom+" "+sujet+" "+telephone+" "+message+" "+mail+" "+entreprise+" "+siret)

            setErrorMessage("Veuillez remplir les champs manquants.")
        }
    }   
        
    // Bonjour, 

    // je  souhaite rejoindre ANDN comme formateur.
    
    // Je maitrise des sujets tel que : 
    // - les langages web : html, css, JS, react, sql
    // - le réseau
    
    // Cordialement, 
    // Melvin BIssor 
    
    
    // ps : Valentin, mon frère, ceci est un test ! 

    return (
        <Row  className="justify-content-md-center">
            <Col md={8}>
                <Row>
                    <h5>Posez vos questions !</h5>
                    <p>
                        Nos équipes vous répondront dans les plus brefs délais.
                    </p>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="nomFormInput" style={{marginBottom:10+"px"}}>
                        <Form.Label column md="2">Nom</Form.Label>
                        <Col>
                            <Form.Control name="nom" type="text" onChange={(event) => setNom(event.target.value)}></Form.Control>
                        </Col>
                        <Form.Label column md="2">Prénom</Form.Label>
                        <Col>
                            <Form.Control name="prenom" type="text"onChange={(event) => setPrenom(event.target.value)}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="telmailFormInput" style={{marginBottom:10+"px"}}>
                        <Form.Label column md="2">Numéro de téléphone</Form.Label>
                        <Col>
                            <Form.Control name="telephone" type="telephone"onChange={(event) => setTelephone(event.target.value)}></Form.Control>
                        </Col>
                        <Form.Label column md="2">Mail</Form.Label>
                        <Col>
                            <Form.Control name="email" type="email"onChange={(event) => setMail(event.target.value)}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="entrepriseInfoFormInput" style={{marginBottom:10+"px"}}>
                        <Form.Label column md="2">Entreprise</Form.Label>
                        <Col>
                            <Form.Control name="entreprise" type="text"onChange={(event) => setEntreprise(event.target.value)}></Form.Control>
                        </Col>
                        <Form.Label column md="2">N° Siret</Form.Label>
                        <Col>
                            <Form.Control name="siret" type="siret"onChange={(event) => setSiret(event.target.value)}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="sujetFormInput" style={{marginBottom:10+"px"}}>
                        <Form.Label column md="2">Sujet</Form.Label>
                        <Col>
                            <Form.Select name="sujet" aria-label="sujet" onChange={(event) => setSujet(event.target.value)}>
                                <option value="">Choisissez un sujet</option>
                                <option value="Détails sur une formation">Détails sur une formation</option>
                                <option value="Détails sur l'inscription">Détails sur l'inscription</option>
                                <option value="Devenir formateur">Devenir formateur</option>
                            </Form.Select>
                        </Col>          
                    </Form.Group>
                    <Form.Group as={Row} controlId="messageFormInput" style={{marginBottom:10+"px"}}>
                        <Form.Label column md="2">Message</Form.Label>
                        <Col>
                            <Form.Control name="message" as="textarea" onChange={(event) => setMessage(event.target.value)} style={{height:200+"px"}}></Form.Control>
                        </Col>          
                    </Form.Group>
                    <Form.Group as={Row} className="justify-content-end" controlId="prenomFormEmail" style={{marginBottom:10+"px"}}>
                        <Col md={3}>
                            <Button type="submit">Contactez Nous !</Button>
                        </Col>
                    </Form.Group>
                </Form>
                <Row className="justify-content-center">
                    {errorMessage}
                </Row>
            </Col>
        </Row>
    )
}

export default ContactForm