import { Col, Row } from "react-bootstrap"

const Domaines = ({nomCategories, listeCategories}) => {
    return(
        <>
            <h4>{nomCategories}</h4>
            <Row md={4}>
                {
                    listeCategories.map((e,index)=>{
                        return <Col key={index}>
                            {e}
                        </Col>
                    })
                }
            </Row>
            <br></br>
        </>
    )
}

export default Domaines