import { Button, Col, Row, Stack } from 'react-bootstrap' 
import Banner from '../../components/banner/Banner'
import DomainesExpertises from '../../components/domaines/DomainesExpertises'
import Presentation from '../../components/presentation/Presentation'
import { useNavigate } from 'react-router'

const Home = () => {
    const navigate = useNavigate()


    return( 
       <Stack gap={1}>
            <Banner/>
            <br></br>
            <div className="center-text">
                TROUVER LE BON PROFIL POUR VOUS ACCOMPAGNER DANS VOTRE EVOLUTION
            </div>
            <br></br>
            <Presentation/>
            <br></br>
            <div className="center-text">
                LA PEDAGOGIE EST DANS NOTRE <span className="andn-text">ANDN</span> !
            </div>
            <DomainesExpertises/>
            <br></br>
            <Row className="justify-content-center">
                
                <Col md="auto" style={{textAlign:"center"}}>
                    <Button onClick={()=>{ navigate("/nous-contacter")}} >Contactez Nous !</Button>
                </Col>
            </Row>
            <br></br>
            <br></br>
       </Stack> 
    )
}

export default Home