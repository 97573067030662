import { Col, Container, Row } from "react-bootstrap"
import "./Teachers.css"

const Teachers = () =>{
    return(
        <Container fluid style={{marginTop:10+"px",marginBottom:50+"px"}}>
            <Row className="justify-content-center" style={{marginTop:40+"px"}}>
                <Col md={10}>
                    <Row>
                        <Col md={5}>
                            <span className="cat-number">
                                01 
                            </span>
                            <br></br>
                            <span className="cat-title">
                                Les conditions d'entrés
                            </span>
                        </Col>
                        <Col >
                            <Row md={2} sm={2}>
                                <Col>
                                    <h6 className="condition-titre">Etre un professionel</h6>
                                    <p className="condition-corps">
                                        Pour connaitre un métier, il faut pratiquer !
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Vouloir transmettre ses compétences</h6>
                                    <p className="condition-corps">
                                        La motivation, c'est la clé !
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Débutant ou expert</h6>
                                    <p className="condition-corps">
                                        Avoir 3 ans d'expérience est un plus, pas un critère ! 
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Echanger et s'adapter</h6>
                                    <p className="condition-corps">
                                        Votre audience sera variée, adapter son discours est essentiel !
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                        <Col md={5}>
                            <span className="cat-number">
                                02 
                            </span>
                            <br></br>
                            <span className="cat-title">
                                Contactez-nous
                            </span>
                        </Col>
                        <Col>
                            <Row md={2}  sm={2}  ld={4}>
                                <Col>
                                    <h6 className="condition-titre">Prenons contact</h6>
                                    <p className="condition-corps">
                                        Remplissez notre formulaire !
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Un entretien téléphonique</h6>
                                    <p className="condition-corps">
                                        Echangeons pour mieux nous connaître !
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Pilule rouge ou pilule bleu</h6>
                                    <p className="condition-corps">
                                        Souhaitez-vous tenter l'aventure ?
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                        <Col md={5}>
                            <span className="cat-number">
                                03 
                            </span>
                            <br></br>
                            <span className="cat-title">
                                A nous de jouer
                            </span>
                        </Col>
                        <Col >
                            <Row md={2}  sm={2}>
                                <Col>
                                    <h6 className="condition-titre">Une mission adaptée</h6>
                                    <p className="condition-corps">
                                        Nous trouvons des missions adaptées à votre profil !
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Transparence assurée</h6>
                                    <p className="condition-corps">
                                        La relation avec le client, on s'en charge ! 
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Un problème ? On gère !</h6>
                                    <p className="condition-corps">
                                        Votre rôle : former ! Notre rôle : gérer vos soucis avec le client ! 
                                    </p>
                                </Col>
                                <Col>
                                    <h6 className="condition-titre">Un accompagnement complet</h6>
                                    <p className="condition-corps">
                                      Vous aider à proposer la meilleure formation possible !
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}


export default Teachers