import { Col, Container, Row } from "react-bootstrap"
import presEcole from "../../assets/formationContinue-slim.jpg"
import "./Presentation.css"

const Presentation = ( ) => {

    return(
        <Container >
            <Row className="justify-content-center">
                <Col md={6}>
                    <h5>
                        Des recruteurs issus du monde professionnel
                    </h5>
                    <p>
                        ANDN Services a été créé par des professionnels de la formation en informatique.
                    </p>
                    <p>
                        Grâce à notre expérience, nous sommes capables de nous assurer des capacités techniques de nos formateurs.
                    </p>
                    <p>
                        Une excellence technique et une communication exemplaire sont 
                        les compétences fondamentales de l’ingénieur moderne.
                    </p>
                </Col>
                <Col md={6}>
                    <img src={presEcole} alt="Des formateurs"/>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={12} sm={12}>
                    <h5>
                        La pédagogie au coeur de nos critères
                    </h5>
                    <p>
                        Avoir des connaissances techniques ne suffit pas pour être formateur.   
                    </p>
                    <p>
                        Pour transmettre ses compétences, il faut être capable de capter son auditoire tout en délivrant un message clair.
                    </p>
                    <h6>
                        Nous accompagnons chaque formateur dans l'élaboration d'un projet pédagogique adapté à la formation.
                    </h6>
                </Col>
            </Row>
        </Container>
    )
}

export default Presentation