import { Container } from "react-bootstrap"
import Domaines from "./Domaines"

const DomainesExpertises = ( ) => {
    let obj = [{
        nomCategories : "Système et réseaux" , listeCategories : ["Architecture réseaux", "Configuration des équipements", "Système Windows et Linux"]
    },{
        nomCategories : "Sécurité informatique" , listeCategories : ["Pentest & Audit", "Sécurité périphérique", "Gouvernance"]
    },{
        nomCategories : "Développement" , listeCategories : ["Développement web", "Développement Java", "Sécurité informatique"]
    },{
        nomCategories : "Management et Communication" , listeCategories : ["Méthode Agile", "Gestion de projet", "Stratégie marketing"]
    }]
    
    return(
        <>
            <Container>
                <br></br>
                <h1>Nos domaines de compétences</h1>
                <br></br>
                {obj.map((e, index) => {
                    return <>
                        <Domaines key={index} nomCategories={e.nomCategories} listeCategories={e.listeCategories}/>
                        <br></br>
                        <hr></hr>
                        <br></br>
                    </>
                })}
            </Container>
        </>
    )

}

export default DomainesExpertises