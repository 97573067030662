import { Col, Container, Row } from "react-bootstrap"
import "./ContactUs.css"
import ContactForm from "../../components/contactForm/ContactForm"
import contact from "../../assets/contactus-slim.jpg"
// import contact from "../../assets/contactus.jpeg"

const ContactUs = () => {
    return (
        <>
            <Row className="justify-content-center">
                {/* <Col md={6}>
                    Vous souhaitez former vos équipes ou vous informez sur une formation particulière, remplisser le formulaire ci-dessous et nous vous recontacterons.
                </Col> */}
                <Col className="text-align NousContacter-Title" style={{paddingTop:10+"px",marginBottom:50+"px"}}>
                    <h2>
                        CONTACTEZ NOUS !
                    </h2>
                </Col>
            </Row>
            <Container fluid>   
                <Row className="justify-content-center align-items-center">
                    <Col md={4} style={{textAlign:"center"}}>
                        <h2>Contactez nous !</h2>
                        <h4>
                            Pour devenir formateur ou pour vous accompagner, remplissez le formulaire ci-dessous.
                        </h4>
                    </Col>
                    <Col md={4}>
                        <img src={contact} alt="Contacter ANDN Services !"/>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <ContactForm/>
            </Container>
        </>
    )

    
}

export default ContactUs