import SocialNetworkBanner from "../socialNetwork/socialNetworkBanner/SocialNetworkBanner"


const Footer = () => {
    return(
        <>
            <footer>
                <SocialNetworkBanner/>

            </footer>
        </>
    )
}

export default Footer