import { Col, Container, Row } from "react-bootstrap"
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa'
import "./SocialNetworkBanner.css"

const SocialNetworkBanner = () => {
    const socialNetwork = [
        {
            name : "Facebook",
            logo : <FaFacebook/>,
            link : "https://www.facebook.com/profile.php?id=100095486975459"
        },
        {
            name : "LinkedIn",
            logo : <FaLinkedin/>,
            link : "https://www.linkedin.com/company/andn-services/"
        },
        // {
        //     name : "Instagram",
        //     logo : <FaInstagram/>,
        //     link : " "
        // }
    ]

    return (
        <Container fluid id="socialNetwork">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h5>Retrouvez-nous sur les réseaux sociaux.</h5>
                </Col>
            </Row>
            <Row className="justify-content-center">
            {
            socialNetwork.map((i, index) => {
                return(
                    <Col className="my-auto socialNetwork" style={{verticalAlign:"middle",padding:"auto"}} key={index} md={2} lg={2}>
                        <a href={i.link} alt={i.name}>
                            <span className="icons">{i.logo}</span>
                            {i.name}
                        </a>
                    </Col>
                    )
                })
            }
            </Row>
        </Container>
    )
}

export default SocialNetworkBanner