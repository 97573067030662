import { Container, Nav,  Navbar } from "react-bootstrap"
import { Link, NavLink }  from 'react-router-dom'
import logo1 from "../../assets/logo1.png"
// import logo1 from "../../assets/logo1-slim.jpeg"
import "./Navigation.css"

const Navigation = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <Link to="/">
                        <img src={logo1} style={{width:10+"em"}}alt="ANDN services"/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <NavLink className="navItem" to="/">
                            Accueil
                        </NavLink>
                        <NavLink className="navItem" to="/devenir-formateur">
                            Devenir formateur
                        </NavLink>
                        <NavLink className="navItem" to="/nous-contacter">
                            Nous contacter
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation