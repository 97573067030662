
import './App.css';
import { Stack } from 'react-bootstrap';
import Navigation from './components/navigation/Navigation';
import { Route, Routes, Outlet } from 'react-router';
import BeTeacher from './pages/beTeacher/BeTeacher';
import ContactUs from './pages/contactUs/ContactUs';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';

function App() {
  return (
    <Stack gap={1}>
      <Navigation/>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/devenir-formateur" element={<BeTeacher/>}/>
        <Route path="/nous-contacter" element={<ContactUs/>}/>
      </Routes>
      <Outlet/>
      <Footer/>
      {/* <Footer/> */}
    </Stack>
  );
}

export default App;
